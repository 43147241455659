
import { Options, Vue } from "vue-class-component";
import Table from "@/app/ui/components/table/index.vue";
import router from "@/app/ui/router";
import { RouteController } from "@/app/ui/controllers/RouteController";
import debounce from "lodash/debounce";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";

import { ROUTE_ROUTE_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    Table
  }
})
export default class RouteManagement extends Vue {
  // Breadcrumb
  breadcrumb: Array<string> = [];

  get getCurrentRoute() {
    return this.$route.meta.title;
  }

  get isDetailAble() {
    return checkRolePermission(ROUTE_ROUTE_MANAGEMENT.DETAIL);
  }

  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" }
  ];
  statusSelect = false;
  statusName = "";

  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.onCloseStatusSelect();
    this.statusName = name;
    RouteController.setStatus(value);
    RouteController.searchAction();
  }

  // Loading
  get isLoading() {
    return RouteController.isLoading;
  }

  columns = [
    {
      name: "ID Route",
      key: "id",
      styleHead: "w-24 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200",
      render: (item: any) => {
        return `<span class='text-center text-red-lp-200'>${item.id}</span>`;
      }
    },
    {
      name: "Nama Route",
      key: "legName",
      styleHead: "w-auto text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${ellipsisString(
          item.route_name,
          70
        )}</div>`;
      }
    },
    {
      name: "Route Code",
      key: "legName",
      styleHead: "w-3/20 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.route_code}</div>`;
      }
    },
    {
      name: "Tipe Route",
      key: "legName",
      styleHead: "w-2/12 text-left",
      styleBody: "text-left rounded px-2 py-0",
      render: (item: any) => {
        return `<div class='text-black-lp-300 mr-2 flex flex-row'><div class='rounded px-2 py-0 bg-gray-lp-200'>${item.route_type}</div></div>`;
      }
    },
    {
      name: "Jumlah Rute",
      key: "transportModa",
      styleHead: "w-3/20 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='text-black-lp-300'>${item.route_count_option}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-1/20 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
        <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${
          item.route_status.toLowerCase() === "active"
            ? "bg-green-lp-300"
            : "bg-red-300"
        } ${
          item.route_status.toLowerCase() === "active"
            ? "text-green-lp-200"
            : "text-red-lp-200"
        }"
      ><span class="capitalize font-medium">${item.route_status.toLowerCase()}</span></div></div>`;
      }
    }
  ];

  // To Detail
  onClickRow(item: any) {
    router.push(`/network/route-management/${item.id}`);
  }

  // Get Data
  created() {
    this.getListData(1, "", "", "", "asc");
    RouteController.setSearch("");
    this.setFirstPage();
    RouteController.setStatus("");
  }

  onReload() {
    RouteController._onGetList({
      page: 1,
      limit: 10,
      status: "",
      originId: "",
      destinationId: "",
      sortBy: "asc"
    });
  }
  getListData(
    page: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ) {
    RouteController._onGetList({
      page,
      limit: 10,
      status,
      originId,
      destinationId,
      sortBy
    });
  }
  setFirstPage() {
    RouteController.setFirstPage();
  }
  get listData() {
    return RouteController.routeData.data;
  }
  onRequest() {
    RouteController.fetchRouteManagement();
  }

  // Pagination
  get pagination() {
    return RouteController.routeData.meta;
  }
  get page() {
    return RouteController.page;
  }
  get limit() {
    return RouteController.limit;
  }
  nextPage() {
    RouteController.setNextPage();
  }

  prevPage() {
    RouteController.setPrevPage();
  }

  toPage = debounce(value => {
    RouteController.setPageAction(value);
  }, 1000);

  // Handle Error
  get isError() {
    return RouteController.isError;
  }
  get errorCause() {
    return RouteController.errorCause;
  }
  get locationSearchOriginList() {
    return RouteController.locationSearchOriginList.locationSearchData;
  }

  get locationSearchDestinationList() {
    return RouteController.locationSearchDestinationList.locationSearchData;
  }

  get locationSearchOriginNotFound() {
    return RouteController.locationSearchOriginNotFound;
  }

  get locationSearchDestinationNotFound() {
    return RouteController.locationSearchDestinationNotFound;
  }

  // select-search-origin
  selectSearchOriginValue = "";
  statusSelectSearchOrigin = false;
  onSelectSearchOrigin(value: string) {
    this.selectSearchOriginValue = value;
    RouteController.getLocationSearchList({
      type: "origin",
      page: 1,
      limit: 10,
      search: value
    });
  }
  onOpenSelectSearchOrigin() {
    this.statusSelectSearchOrigin = true;
    const value = this.selectSearchOriginValue;
    value.length === 0
      ? RouteController.getLocationSearchList({
          type: "origin",
          page: 1,
          limit: 10,
          search: "jaka"
        })
      : RouteController.getLocationSearchList({
          type: "origin",
          page: 1,
          limit: 10,
          search: value
        });
  }
  onCloseSelectSearchOrigin() {
    this.statusSelectSearchOrigin = false;
  }
  onChooseSelectSearchOrigin(name: string, code: string) {
    this.selectSearchOriginValue = name;
    this.statusSelectSearchOrigin = false;
    RouteController.selectSearchOriginAction(code);
  }

  // select-search destination
  selectSearchDestinationValue = "";
  statusSelectSearchDestination = false;
  onSelectSearchDestination(value: string) {
    this.selectSearchDestinationValue = value;
    RouteController.getLocationSearchList({
      type: "destination",
      page: 1,
      limit: 10,
      search: value
    });
  }
  onOpenSelectSearchDestination() {
    this.statusSelectSearchDestination = true;
    const value = this.selectSearchDestinationValue;
    value.length === 0
      ? RouteController.getLocationSearchList({
          type: "destination",
          page: 1,
          limit: 10,
          search: "jaka"
        })
      : RouteController.getLocationSearchList({
          type: "destination",
          page: 1,
          limit: 10,
          search: value
        });
  }
  onCloseSelectSearchDestination() {
    this.statusSelectSearchDestination = false;
  }
  onChooseSelectSearchDestination(name: string, code: string) {
    this.statusSelectSearchDestination = false;
    this.selectSearchDestinationValue = name;
    RouteController.selectSearchDestinationAction(code);
  }
}
