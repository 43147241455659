
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import Toggle from "@/app/ui/components/toggle/index.vue";
import {
  dateToDateString,
  dateToTime,
  ellipsisString,
  firstCapitalize
} from "@/app/infrastructures/misc/Utils";
import { RouteController } from "@/app/ui/controllers/RouteController";

import { RouteOptions } from "@/domain/entities/Route";
import RouteOptionsSection from "./route-options.vue";

@Options({
  components: {
    Input,
    Toggle,
    RouteOptionsSection
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.onCloseSave();
    this.$router.push(`/network/route-management/${this.id}`);
  }

  isDisabled = true;

  // Loading
  get isLoading() {
    return RouteController.isLoading;
  }

  // Get Detail Data
  created() {
    this.getDetail();
  }

  get id() {
    return Number(this.$route.params.id);
  }

  getDetail() {
    RouteController._onGetDetail(this.id);
  }
  get detailData(): any {
    return RouteController.routeDetailData;
  }
  get canActiveRoute(): boolean {
    return !!this.detailData.route_option.find(
      (e: RouteOptions) => !e.option_status
    );
  }

  // Toggle Route
  openRoute = false;
  onOpenRoute() {
    this.openRoute = true;
  }
  onCloseRoute() {
    this.openRoute = false;
  }
  get isRoute() {
    return RouteController.isRoute;
  }
  changeRoute(value: boolean) {
    RouteController.setRoute(value);
    this.openRoute = false;
  }

  // Modal Success
  messageSuccess = "";
  onCloseSuccess() {
    return RouteController.setOpenSuccess(false);
  }
  get openSuccess() {
    return RouteController.openSuccess;
  }

  // Save data
  get openModal() {
    return RouteController.openModal;
  }
  onOpenSave() {
    RouteController.setOpenModal(true);
  }
  onCloseSave() {
    RouteController.setOpenModal(false);
  }

  async onEdit() {
    this.onCloseSave();
    RouteController._onEdit({
      id: this.id,
      isRoute: this.isRoute ? "active" : "inactive"
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Ellipsis String
  ellipsisString(text: string) {
    return ellipsisString(text, 50);
  }

  // Type Route Format
  typeRoute(text: string) {
    return text
      ? `${firstCapitalize(text.split("-")[0])} to ${firstCapitalize(
          text.split("-")[1]
        )}`
      : "";
  }

  // Format Date
  formatDate(date: string) {
    return date
      ? `${dateToDateString(new Date(date))} ${dateToTime(new Date(date))} WIB`
      : "";
  }

  // Handle Error
  get isError() {
    return RouteController.isError;
  }
  get errorCause() {
    return RouteController.errorCause;
  }
  handleErrorClose() {
    RouteController.setErrorEdit(false);
  }
  get isErrorEdit() {
    return RouteController.isErrorEdit;
  }
}
