
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue, prop } from "vue-class-component";
import Accordion from "@/app/ui/components/accordion/index.vue";
import OverlayPanel from "primevue/overlaypanel";
import { RouteController } from "@/app/ui/controllers/RouteController";
import {
  ellipsisString,
  firstCapitalize
} from "@/app/infrastructures/misc/Utils";
import { RouteOptions as RouteOptionsData } from "@/domain/entities/Route";
import { BadgeResponse } from "@/domain/entities/MainApp";
import Tooltip from "@/app/ui/components/tooltip/index.vue";

class Props {
  isEdit = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  components: {
    Accordion,
    OverlayPanel,
    Tooltip
  }
})
export default class RouteOptions extends Vue.with(Props) {
  get detailData(): any {
    return RouteController.routeDetailData;
  }

  // Opsi Route
  onOpenOpsi = (index: number) => {
    this.detailData.route_option[index].isOpen = !this.detailData.route_option[
      index
    ].isOpen;
  };

  // Ellipsis String
  ellipsisString(text: string) {
    return ellipsisString(text, 50);
  }

  // Type Route Format
  typeRoute(text: string) {
    return text
      ? `${firstCapitalize(text.split("-")[0])} to ${firstCapitalize(
          text.split("-")[1]
        )}`
      : "";
  }

  // default confirm
  defaultConfirm = false;
  tmpOption: RouteOptionsData | undefined = new RouteOptionsData(
    "",
    false,
    [],
    false,
    []
  );
  setDefaultConfirm(value: boolean, data?: RouteOptionsData) {
    this.tmpOption = data;
    this.defaultConfirm = value;
  }
  setDefaultOption() {
    let data = new RouteOptionsData("", false, [], false, []);
    this.detailData.route_option.map((option: RouteOptionsData) => {
      if (option.group_option_name === this.tmpOption?.group_option_name) {
        option["is_default"] = true;
        data = option;
      } else {
        option["is_default"] = false;
      }
    });
    RouteController.setRouteOptionData(data);
    this.defaultConfirm = false;
  }

  isConfirmToggle = false;
  onConfirmToggle(value: boolean): void {
    this.isConfirmToggle = value;
  }

  indexOption = 0;
  onChangeToggle(index: number): void {
    this.indexOption = index;
    if (this.detailData.route_option[index].option_status) {
      this.onConfirmToggle(true);
    } else {
      this.onSetOptionStatus(true);
    }
  }

  onSetOptionStatus(value: boolean): void {
    this.detailData.route_option[this.indexOption].option_status = value;
    this.onConfirmToggle(false);
  }

  get modalData(): BadgeResponse {
    return new BadgeResponse({
      title: this.defaultConfirm ? "Jadikan Utama?" : "Konfirmasi Penonaktifan",
      message: this.defaultConfirm
        ? "Anda yakin ingin membuat opsi ini sebagai rute <br/> utama?"
        : "Penonaktifan status akan membuat rute tidak dapat digunakan dalam Rute Mapping.",
      icon: this.defaultConfirm ? "are-you-sure?" : "inactive-location",
      textCancel: "Kembali",
      textSuccess: this.defaultConfirm ? "Jadikan Utama" : "Nonaktifkan",
      onSubmit: this.defaultConfirm
        ? this.setDefaultOption
        : () => this.onSetOptionStatus(false),
      onClose: this.defaultConfirm
        ? () => this.setDefaultConfirm(false)
        : () => this.onConfirmToggle(false)
    });
  }

  isOpenTooltip = false;
  OpenTooltipId = 0;

  openTooltip(id: number) {
    this.isOpenTooltip = true;
    this.OpenTooltipId = id;
  }

  closeTooltip() {
    this.isOpenTooltip = false;
    this.OpenTooltipId = 0;
  }
}
