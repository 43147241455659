
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";

@Options({
  name: "Toggle",
  props: {
    onChange: {
      default: Function as PropType<() => void>,
      type: Function
    },
    isActive: {
      default: false,
      type: Boolean
    }
  }
})
export default class extends Vue {}
