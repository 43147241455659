
import { Options, Vue } from "vue-class-component";
import {
  checkRolePermission,
  dateToDateString,
  dateToTime,
  ellipsisString,
  firstCapitalize
} from "@/app/infrastructures/misc/Utils";

import { RouteController } from "@/app/ui/controllers/RouteController";
import { ROUTE_ROUTE_MANAGEMENT } from "@/app/infrastructures/misc/RolePermission";
import RouteOptions from "./route-options.vue";
import { SlaCityToCity } from "@/domain/entities/Route";

@Options({
  components: {
    RouteOptions
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    isEditAble() {
      return checkRolePermission(ROUTE_ROUTE_MANAGEMENT.EDIT);
    }
  }
})
export default class Detail extends Vue {
  goBack() {
    this.$router.push(`/network/route-management`);
  }

  goEdit(id: number) {
    this.$router.push(`/network/route-management/${id}/edit`);
  }

  // Loading
  get isLoading() {
    return RouteController.isLoading;
  }

  // Get Detail Data
  created() {
    this.getDetail();
  }

  get id() {
    return Number(this.$route.params.id);
  }

  getDetail() {
    RouteController._onGetDetail(this.id);
  }

  get detailData(): any {
    return RouteController.routeDetailData;
  }

  // Ellipsis String
  ellipsisString(text: string) {
    return ellipsisString(text, 50);
  }

  // Type Route Format
  typeRoute(text: string) {
    return text
      ? `${firstCapitalize(text.split("-")[0])} to ${firstCapitalize(
          text.split("-")[1]
        )}`
      : "";
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))}, ${dateToTime(
      new Date(date)
    )} WIB`;
  }

  // Handle Error
  get isError() {
    return RouteController.isError;
  }
  get errorCause() {
    return RouteController.errorCause;
  }

  // sla city to city
  get columns() {
    return [
      {
        name: "Produk",
        styleHead: "w-2/4 text-left whitespace-no-wrap align-top",
        render: (item: SlaCityToCity) => {
          return `<div class="overflow-ellipsis text-left">${item.productName}</div>`;
        }
      },
      {
        name: "Minimum SLA",
        styleHead: "w-1/4 text-left whitespace-no-wrap align-top",
        render: (item: SlaCityToCity) => {
          return `<div class="overflow-ellipsis text-left">${item.minSla}</div>`;
        }
      },
      {
        name: "Maximum SLA",
        styleHead: "w-1/4 text-left whitespace-no-wrap align-top",
        render: (item: SlaCityToCity) => {
          return `<div class="overflow-ellipsis text-left">${item.maxSla}</div>`;
        }
      }
    ];
  }
}
